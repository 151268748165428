/* cyrillic-ext */
@font-face {
  font-family: '__Caveat_84d9cc';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(/_next/static/media/e7b354626d982aa8-s.woff2) format('woff2');
  unicode-range: U+0460-052F, U+1C80-1C8A, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}
/* cyrillic */
@font-face {
  font-family: '__Caveat_84d9cc';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(/_next/static/media/147bfce26dd9c7af-s.woff2) format('woff2');
  unicode-range: U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}
/* latin-ext */
@font-face {
  font-family: '__Caveat_84d9cc';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(/_next/static/media/c59e126942bb935a-s.woff2) format('woff2');
  unicode-range: U+0100-02BA, U+02BD-02C5, U+02C7-02CC, U+02CE-02D7, U+02DD-02FF, U+0304, U+0308, U+0329, U+1D00-1DBF, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20C0, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: '__Caveat_84d9cc';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(/_next/static/media/05f970ec0bd776d8-s.p.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* cyrillic-ext */
@font-face {
  font-family: '__Caveat_84d9cc';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url(/_next/static/media/e7b354626d982aa8-s.woff2) format('woff2');
  unicode-range: U+0460-052F, U+1C80-1C8A, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}
/* cyrillic */
@font-face {
  font-family: '__Caveat_84d9cc';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url(/_next/static/media/147bfce26dd9c7af-s.woff2) format('woff2');
  unicode-range: U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}
/* latin-ext */
@font-face {
  font-family: '__Caveat_84d9cc';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url(/_next/static/media/c59e126942bb935a-s.woff2) format('woff2');
  unicode-range: U+0100-02BA, U+02BD-02C5, U+02C7-02CC, U+02CE-02D7, U+02DD-02FF, U+0304, U+0308, U+0329, U+1D00-1DBF, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20C0, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: '__Caveat_84d9cc';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url(/_next/static/media/05f970ec0bd776d8-s.p.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}@font-face {font-family: '__Caveat_Fallback_84d9cc';src: local("Arial");ascent-override: 124.77%;descent-override: 38.99%;line-gap-override: 0.00%;size-adjust: 76.94%
}.__className_84d9cc {font-family: '__Caveat_84d9cc', '__Caveat_Fallback_84d9cc';font-style: normal
}.__variable_84d9cc {--font-logo: '__Caveat_84d9cc', '__Caveat_Fallback_84d9cc'
}

@font-face {
font-family: '__geistSans_1e4310';
src: url(/_next/static/media/4473ecc91f70f139-s.p.woff) format('woff');
font-display: swap;
font-weight: 100 900;
}@font-face {font-family: '__geistSans_Fallback_1e4310';src: local("Arial");ascent-override: 85.83%;descent-override: 20.52%;line-gap-override: 9.33%;size-adjust: 107.19%
}.__className_1e4310 {font-family: '__geistSans_1e4310', '__geistSans_Fallback_1e4310'
}.__variable_1e4310 {--font-geist-sans: '__geistSans_1e4310', '__geistSans_Fallback_1e4310'
}

@font-face {
font-family: '__geistMono_c3aa02';
src: url(/_next/static/media/463dafcda517f24f-s.p.woff) format('woff');
font-display: swap;
font-weight: 100 900;
}@font-face {font-family: '__geistMono_Fallback_c3aa02';src: local("Arial");ascent-override: 69.97%;descent-override: 16.73%;line-gap-override: 7.61%;size-adjust: 131.49%
}.__className_c3aa02 {font-family: '__geistMono_c3aa02', '__geistMono_Fallback_c3aa02'
}.__variable_c3aa02 {--font-geist-mono: '__geistMono_c3aa02', '__geistMono_Fallback_c3aa02'
}

